import * as React from "react";

function SvgLogoAgh(props) {
  return (
    <svg viewBox="0 0 63.07 122.44" {...props}>
      <path
        d="M12.74 122.01h5.29l-5.86-16.48a2.87 2.87 0 00-2.53-2.13H4.52c.79.64 1.06 1.05 1.06 1.7a6.41 6.41 0 01-.51 1.95l-5.07 15h3.42l1.53-4.64h6.17zm-2.55-7.52H5.94l2.1-6.35zM34.19 119.41v-6a2.76 2.76 0 00-1-2.43h3.82a2.18 2.18 0 012.19 2.16v8.43a19.78 19.78 0 01-5.49.85c-6.61 0-10.81-3.86-10.81-9.57s4.2-9.92 10.9-9.92a12.51 12.51 0 014.29.75v3.73a7.86 7.86 0 00-4.29-1.24c-3.21 0-5.74 2.22-5.74 6.31 0 4.33 1.92 7 5 7a6 6 0 001.13-.07zM52.42 105.8a2.36 2.36 0 00-2.34-2.4h-3.66a2.74 2.74 0 011.05 2.43v16.18h5v-7.83h5.69v7.83h5v-18.6h-5v7.58h-5.74z"
        fill="#231f20"
      />
      <path
        d="M44.98 90.99c4.14-2.85 7.76-7.24 7.76-16.55V18.61h4.13v55.8c0 10.37-5.17 14.51-11.89 16.58z"
        fill="#b10d28"
      />
      <path
        d="M48.34 90.99c3.88-1.81 10.6-5.17 10.6-16.55V18.61h4.14v55.8c0 14-10.86 16.06-14.74 16.58z"
        fill="#b10d28"
      />
      <path
        d="M42.42 90.99c3.08-3.11 4.13-9.31 4.13-17.58v-54.8h4.14v55.8c-.02 9.31-3.12 14-8.27 16.58z"
        fill="#b10d28"
      />
      <path
        d="M22.24 0c4.13 2.84 7.75 7.24 7.75 16.54v64.11h4.14V16.54C34.13 6.2 28.96 2.07 22.24 0z"
        fill="#231f20"
      />
      <path
        d="M25.6 0c3.88 1.81 10.6 5.17 10.6 16.54v64.11h4.13V16.54C40.33 2.58 29.48.52 25.6 0z"
        fill="#231f20"
      />
      <path
        d="M19.65 0c3.09 3.1 4.14 9.3 4.14 17.58v63.07h4.13V16.54c0-9.28-3.1-13.96-8.27-16.54z"
        fill="#231f20"
      />
      <path
        d="M19.13 90.99c-4.13-2.85-7.75-7.24-7.75-16.55V18.61H7.24v55.8c0 10.37 5.18 14.51 11.89 16.58z"
        fill="#00723f"
      />
      <path
        d="M15.77 90.99c-3.88-1.81-10.6-5.17-10.6-16.55V18.61H1.04v55.8c0 14 10.85 16.06 14.73 16.58z"
        fill="#00723f"
      />
      <path
        d="M21.72 90.99c-3.09-3.11-4.14-9.31-4.14-17.58v-54.8h-4.16v55.8c0 9.31 3.13 14 8.3 16.58z"
        fill="#00723f"
      />
    </svg>
  );
}

export default SvgLogoAgh;

