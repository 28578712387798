import React from 'react';

export const Content = ({children}) => {
  return (
    <div className='flex flex-1 bg-gray-300 pb-3'>
      <div className="invisible sm:invisible md:w-1/12 xl:w-1/4"/>
      <div className="w-full sm:w-full md:w-5/6 xl:w-1/2">{children}</div>
      <div className="invisible sm:invisible md:w-1/12 xl:w-1/4"/>
    </div>
  )
}
