import * as React from "react"

function InstagramIcon(props) {
  return (
    <svg
      className="prefix__icon"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="currentColor" d="M0 0zM778.24 512c0 147.04-119.232 266.24-266.24 266.24-147.04 0-266.24-119.2-266.24-266.24 0-17.504 1.728-34.624 5.024-51.2H71.68v353.888c0 75.968 61.536 137.632 137.632 137.632h605.376c75.968 0 137.632-61.6 137.632-137.632V460.8H773.28a267.357 267.357 0 014.96 51.2zm36.448-440.32H209.312c-76.032 0-137.632 61.632-137.632 137.632V358.4h222.912C342.784 290.304 422.176 245.76 512 245.76s169.216 44.544 217.408 112.64H952.32V209.312c0-75.968-61.632-137.632-137.632-137.632zm67.328 169.984c0 13.568-11.072 24.576-24.576 24.576h-73.664c-13.504 0-24.576-11.072-24.576-24.576v-73.728c0-13.568 11.104-24.576 24.576-24.576h73.664c13.568 0 24.576 11.072 24.576 24.576v73.728zM675.84 512c0-90.528-73.376-163.84-163.84-163.84S348.16 421.472 348.16 512 421.536 675.84 512 675.84 675.84 602.528 675.84 512z" />
    </svg>
  )
}

export default InstagramIcon
