import React from 'react';
import {Header} from '../components/Header';
import {Content} from '../components/Content';
import {Layout} from '../components/Layout';
import {Footer} from '../components/Footer';
import {TopMenu} from "../components/TopMenu";
import {SearchResults} from "../components/SearchResults";

export const Search = () => {
    return (
        <Layout>
            <Header/>
            <TopMenu/>
            <Content>
                <SearchResults/>
            </Content>
            <Footer/>
        </Layout>
    );
}
