import React, {useEffect, useState} from 'react';
import {NavCardWrapper} from './NavCardWrapper';
import {NavCard} from './NavCard';
import {useHistory} from 'react-router-dom';
import {API} from "../../services/API";

export const Menu = ({menu = 'study'}) => {
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState([])
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.get('wp/v2/menu/Menu%201')
      setMenuItems(response.data);
    }
    fetchData();
  }, [])

  useEffect(() => {
    if(menuItems.length > 1)
      setSelectedMenu(menuItems.find(entry => entry.url.includes(menu)).items)
  }, [menu, menuItems])

  const handleSelectMenu = (entry) => {
    history.push(`/index${entry.url}`)
  }

  return (
    <>
      <div className='w-full sm:w-full md:w-5/6 xl:w-1/2 ml-auto mr-auto '>
        <div className='flex bg-white'>
          <div className="w-full h-full ml-auto mr-auto">
            <div className={`grid grid-cols-2 sm:grid-cols-4 cursor-pointer gap-0 divide-x divide-gray-400 border-r border-l border-gray-400`}>
              {
                menuItems.map((entry, i) => (
                <div
                  onClick={() => handleSelectMenu(entry)}
                  key={entry.ID}
                  className={"h-55px hover:bg-light-green " +
                  "hover:text-white font-medium text-center pt-4 " +
                  "border-t border-b border-gray-400 sm:border-t-0 sm:border-b-0 "
                  + (entry.url.includes(menu) ? 'bg-light-green text-white' : '')}>
                  <span>{entry.title}</span>
                </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
        <div className='flex flex-1 bg-gray-300'>
          <div className="invisible sm:invisible md:w-1/12 xl:w-1/4"/>
          <div className="w-full sm:w-full md:w-5/6 xl:w-1/2">
            <NavCardWrapper>
              {
                selectedMenu.map(item => (
                  <NavCard key={item.ID} title={item.title} description={item.description}  url={item.url}/>
                ))
              }
            </NavCardWrapper>
          </div>
          <div className="invisible sm:invisible md:w-1/12 xl:w-1/4"/>
        </div>
    </>
  );
};
