import React from 'react';
import styles from './Header.module.css';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import SvgLogoWiMiIpPl from "./wimiip";
import SvgLogoAgh from "./agh";

export const Header = () => {
  return (
    <header className='w-full'>
      <div className={classNames('flex', 'justify-between', styles.container)}>
        <div className='flex'>
          <div>
            <Link to='/'>
              <SvgLogoWiMiIpPl width={55} height={55}/>
            </Link>
          </div>
          <div>
            <Link to='/'>
              <span className='font-bold text-14px mt-2 sm:mt-4 ml-2 block'>Wydział Inżynierii Metali i Informatyki Przemysłowej</span>
            </Link>
          </div>
        </div>
        <div className='flex'>
            <div className={styles.langContainer}>
              {/*<span className={styles.lang}>PL</span>*/}
              {/*<span className={styles.lang}>EN</span>*/}
            </div>
            <div>
              <a href='https://agh.edu.pl'>
                  <SvgLogoAgh width={30}/>
              </a>
            </div>
         </div>
      </div>
    </header>
  )
}
