import React from 'react'
import {navigate} from "../../services/Markup";
import {useHistory} from "react-router";

export const SliderContent = ({title, linkText, linkUrl, featureImage}) => {
    const history = useHistory();

    return (
        <div className='w-full flex'>
            <div className='w-2/5'>
                <img alt='Feature'
                     src={featureImage}/>
            </div>

            <div className='w-3/5 ml-1 mt-2 self-center max-h-235px overflow-hidden'>
                      <span
                          className='text-xl sm:text-xl md:text-lg lg:text-lg xl:text-24px font-bold overflow-hidden break-words text-white select-none text-shadow'>
                          {title}
                      </span>
                <span onClick={() => navigate(linkUrl, history)} className='block mt-3 cursor-pointer text-white select-none'>
                          {linkText}

                </span>

            </div>

            {/*<div className='absolute' style={{top: 215, left: 180}}>*/}
            {/*    <SvgIconCircle width={22} className='mx-1 inline-block'/>*/}
            {/*    <SvgIconCircle width={22} className='mx-1 inline-block'/>*/}
            {/*    <SvgIconCircle width={22} className='mx-1 inline-block'/>*/}
            {/*</div>*/}
        </div>
    )
}
