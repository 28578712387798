import React from 'react';
import styles from './FooterItem.module.scss';
import {createMarkup} from "../../../services/Markup";

export const FooterItem = ({children, content, className = ''}) => {
  return (
    <div className={'text-white sm:min-h-213px px-4 mr-auto ml-auto' + className}>
      <div className={styles.item} dangerouslySetInnerHTML={createMarkup(content)}>
        {children}
      </div>
    </div>
  )
}
