import React, {useCallback, useEffect, useState} from 'react'
import {SliderContent} from "./SliderContent";
import {AnimatePresence, motion} from "framer-motion";
import {SvgIconCircle} from "./SvgIconCircle";


const directionOffset = 250;
const swipeConfidenceThreshold = 100;
const slideInterval = 5000; //ms
const transition = {
    duration: 0.4
}

export const Slider = ({data}) => {
    const [[page, direction], setPage] = useState([0, 0]);
    const [timerActive, setTimerActive] = useState(true);


    const handlePaginateCb = useCallback((newDirecton) => {
        const paginate = newDirection => {
            if (page + newDirection >= 0 && page + newDirection < data.length) {                                   // nextSlide
                setPage([page + newDirection, newDirection]);
            } else if (page + newDirection === data.length) {                          // on the last slide
                setPage([0, 1]);
            } else if (page + newDirection === -1) {                                    // prevSlide
                const newPage = data.length - 1
                setPage([newPage, -1]);
            }
        };
        paginate(newDirecton)
    }, [page, data])

    useEffect(() => {
        let timer;
        if(timerActive) {
            timer = setInterval(() => {
                handlePaginateCb(1);
            }, slideInterval);
        }

        return () => clearInterval(timer);

    }, [page, timerActive, handlePaginateCb]);


    const nextSlide = () => {
        setTimerActive(false);
        handlePaginateCb(1)
    }

    const prevSlide = () => {
        setTimerActive(false);
        handlePaginateCb(-1)
    }


    const variants = {
        exit: direction => ({
            x: direction < 0 ? -directionOffset : directionOffset,
            opacity: 0,
            transition

        }),
        enter: {
            x: direction < 0 ? directionOffset : -directionOffset,
            opacity: 1,
            transition
        },
        center: {
            x: 0,
            opacity: 1,
            transition
        }
    }

    const swipePower = (offset, velocity) => {
        if(velocity === 0) velocity = 10;
        return Math.abs(offset) * velocity;
    };

    const onDragStart = (event, info) => {
        setTimerActive(false);
    }

    const onDragStop = (event, info) => {
        const {offset, velocity} = info;
        const swipe = swipePower(offset.x, velocity.x);
        if (swipe < swipeConfidenceThreshold) {
            handlePaginateCb(-1);
        } else if (swipe > -swipeConfidenceThreshold) {
            handlePaginateCb(1);
        }
    }

    const handleChangePage = (targetPage) => {
        if(targetPage > page[0])
            setPage([targetPage, 1])
        else setPage([targetPage, -1])
    }


    return (
        <div className='relative flex items-center justify-center h-full'>
            <div className='cursor-pointer w-0 sm:w-23px mr-4 invisible sm:visible' onClick={() => prevSlide()}>
                <img src='/assets/images/icon_arrow.png'
                     className='rotate-180 transform'
                     alt='Next'/>
            </div>

            <div className='flex flex-wrap max-w-450px h-235px bg-no-repeat bg-contain border-4 border-gray-100 px-2 py-4 overflow-hidden'>
                <div className='flex w-full '>
                <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
                        <motion.div
                            custom={direction}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            key={page}
                            variants={variants}
                            drag="x"
                            layout
                            onDragEnd={onDragStop}
                            onDragStart={onDragStart}
                        >
                            <SliderContent title={data[page]?.title} linkText={data[page]?.linkText} linkUrl={data[page]?.linkUrl} featureImage={data[page]?.image}/>
                        </motion.div>
                </AnimatePresence>
                </div>
                <div className='flex w-full'>
                <div className='flex w-1/6 mx-auto pt-4 self-end h-8 sm:h-10'>
                    {
                        data.map((item, index) => <SvgIconCircle key={`circle-${index}`} className='cursor-pointer' selected={index === page} width={16} height={16} onClick={() => handleChangePage(index)}/>)
                    }
                </div>
                </div>


            </div>

            <div className='cursor-pointer w-0 sm:w-23px ml-4 invisible sm:visible'>
                <img src='/assets/images/icon_arrow.png' className='' alt='prev' onClick={() => nextSlide()}/>
            </div>
        </div>
    )
}
