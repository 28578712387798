import React, {useEffect, useState} from 'react';
import {Layout} from '../components/Layout';
import {Header} from '../components/Header';
import {TopMenu} from '../components/TopMenu';
import {NewsContent} from '../components/NewsContent';
import {useParams} from 'react-router-dom';
import {API} from '../services/API';
import {Footer} from "../components/Footer";
import {useMatomo} from "@datapunt/matomo-tracker-react";

export const NewsPage = () => {
  const [data, setData] = useState({title: '', content: ''});
  const [loading, setLoading] = useState(true)
    const {trackPageView} = useMatomo()
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const response = await API.get(`/wimiip/v1/news/${id}`)
        setData(response.data)
        setLoading(false);
    }
    fetchData();
  }, [id])

    useEffect(() => {
        trackPageView()
    }, [trackPageView])

  return (
    <Layout>
      <Header/>
      <TopMenu breadcrumbs={[]}/>
      <NewsContent news={data} loading={loading}/>
      <Footer/>
    </Layout>
  );
}
