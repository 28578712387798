import React from 'react'
import {Link} from "react-router-dom";

const ResultItem = ({item}) => {
    return (
    <div className="flex flex-wrap md:flex-no-wrap py-4 border-gray-300 ">
        <div className="md:flex-grow">
            <Link to={item.link}>
                <h2 className="text-2xl font-medium text-gray-900 title-font mb-2 ">{item.title}</h2>

                <p className="leading-relaxed">{item.content}</p>
            </Link>
        </div>
    </div>
    )
}

export const Results = ({results}) => {
    return (
        <section className="text-gray-700 body-font overflow-hidden bg-white border-2 border-white rounded-lg">
            <div className="container px-5 mx-auto divide-y-2">
                {
                    results.length > 0 && results.map(item => <ResultItem key={item.id} item={item}/> )
                }

                {
                    results.length == 0 && <div className="py-2"> Brak wyników wyszukiwania </div>
                }
                </div>
        </section>
    )
}
