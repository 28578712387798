import React, {useEffect, useState} from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import {Header} from '../components/Header';
import {Content} from '../components/Content';
import {Layout} from '../components/Layout';
import {TopMenu} from '../components/TopMenu';
import {Menu} from '../components/Menu';
import {NewsBar} from '../components/NewsBar';
import {NewsWrapper} from '../components/NewsWrapper';
import {NewsCard} from '../components/NewsCard';
import {Footer} from '../components/Footer';
import {API} from '../services/API';
import {useParams} from "react-router-dom";
import {SearchBanner} from "../components/SearchBanner";

export const Front = () => {
    const {trackPageView} = useMatomo()
    const [newsData, setNewsData] = useState([])

    const {category} = useParams()

    useEffect(() => {
        const fetchData = async () => {
            const response = await API.get('/wimiip/v1/news/')
            // for(const news of response.data){
            //   newsArray.push({
            //     id: news.id,
            //     type: news.category_ids[0],
            //     header: news.media.large,
            //     title: news.title,
            //     date: news.date.substring(0, 10),
            //     url: `/news/${news.id}`,
            //   })
            // }
            setNewsData(response.data)
        }
        fetchData();
    }, [])


    useEffect(() => {
        trackPageView()
    }, [trackPageView])

  return (
      <Layout>
        <Header/>
        <TopMenu/>
        <SearchBanner/>
        <Menu menu={category}/>
        <Content>
          <NewsWrapper>
            {
              newsData.slice(0, 3).map((news) => (
                <NewsCard key={news.ID}
                          type={news.categories[0]}
                          header={news.thumbnail}
                          title={news.title}
                          date={news.date}
                          isExternal={news.redirect_url !== ''}
                          url={news.redirect_url !== '' ? news.redirect_url : `/news/${news.ID}`}/>
              ))
            }
          </NewsWrapper>
          <NewsBar news={newsData}/>
        </Content>
        <Footer/>
      </Layout>
  );
}
