import React from 'react';
import cn from 'classnames';
import styles from './NewsContent.module.scss';
import {createMarkup, navigate} from '../../services/Markup';
import {Loading} from '../Loading';
import {useHistory} from 'react-router';

export const NewsContent = ({news, loading}) => {
  const history = useHistory();
  const {title, date, sneakpeak, content, links, redirect_url} = news;

  if(redirect_url){
    window.location.replace(redirect_url);
  }

  const category = news.categories?.[0]

  let categoryText = '';
  let categoryColor = '';

  switch(category){
    case 5:
      categoryText = 'OGŁOSZENIE';
      categoryColor = 'text-red';
      break;
    case 6:
      categoryText = 'WYDARZENIE';
      categoryColor = 'text-agh-green';
      break;
    default:
      categoryText = 'AKTUALNOŚCI';
      categoryColor = 'text-black';
  }


  const clickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    navigate(targetLink, history)
  }


  return (
    <div className='flex flex-1 bg-white pb-6 mx-4'>
      <div className="w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 ml-auto mr-auto">
        <div className='mt-30px text-14px font-bold'>
          <span className={cn(categoryColor)}>{categoryText}</span>
          <span className='ml-4 text-dark-grey'>OPUBLIKOWANO: {date?.substring(0,10)}</span>
        </div>
        <h1 className='text-38px font-black leading-48px mt-30px '>{title}</h1>
        <div className='mt-10 text-dark-grey text-28px leading-36px'>
          {sneakpeak}
        </div>
        <div className={cn('text-justify, text-dark-grey', styles.content)}>
          <div
            onClick={clickHandler}
            dangerouslySetInnerHTML={createMarkup(content)}
          />

          {loading &&
          <Loading/>
          }
        </div>
        <div className='mt-10 text-dark-grey'>
          <div className='border-t inline-block'>
            {links && links.map(link => (
              <div key={link.url} className='uppercase text-16px'>
                <span>
                {link.text}
                <img src='/assets/images/trojkat.png' className='inline-block' alt='more'/>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
