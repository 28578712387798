import React, {useState} from 'react';
import {motion} from 'framer-motion';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {newsStyle} from '../../services/NewsStyle';
import {SvgIconArrow} from './SvgIconArrow';
import {format, parseISO} from "date-fns";
import {pl} from "date-fns/locale";
import { NewsLink } from '../NewsCard/NewsLink';

const variants = {
  open: {
    height: '100%',
    transition: {
      duration: 0.5,
    },
  },
  closed: {
    height: 45,
    transition: {
      duration: 0.5,
    },
  },

};

export const NewsBar = ({ news = [] }) => {
  const [open, setOpen] = useState(false);


  return (
    <div className="block mt-3 w-full">
      <motion.div
        initial='closed'
        variants={variants}
        animate={open ? 'open' : 'closed'}
        className="bg-white flex flex-wrap justify-between h-43px border border-solid border-gray-400 shadow overflow-hidden">
        <div className="ml-6 mt-10px font-bold text-16px font-bold mx-1 uppercase ">
          <span>AKTUALNOŚCI</span>
        </div>
        <div className='flex' onClick={() => setOpen(!open)}>
          <div className="mt-10px text-14px text-light-grey uppercase inline-block cursor-pointer">
            {
              open && <span>Wyświetl mniej aktualności</span>
            }
            {
              !open && <span>Wyświetl więcej aktualności</span>
            }

          </div>
          <div className="inline-block"><img className={cn('transform rotate-90 cursor-pointer', open && '-rotate-90')}
                                             src='/assets/images/trojkat.png' alt='More'/></div>
        </div>
        <div className='w-full h-full mx-6 my-10px'>
          <section className="text-gray-700 body-font overflow-hidden">
            <div className="container mx-auto divide-y-2">
              {
                news.map(item => {
                  const { categoryText, categoryColor } = newsStyle(item.categories[0]);
                  return (
                  <div key={item.ID} className="py-2 flex flex-no-wrap ">
                    <div className="md:w-48 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
                      <span className={"font-medium title-font text-gray-900 " + categoryColor}>{categoryText}</span>
                      <span className="mt-1 text-gray-500 text-sm uppercase">{format(parseISO(item.date), 'dd MMMM yyyy', { locale: pl })}</span>
                    </div>
                    <div className="md:flex-grow md:flex-nowrap">
                      <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">{item.title}</h2>
                      <div className="lg:text-right">
                        <NewsLink to={`/news/${item.ID}`} isExternal={item.redirect_url !== ''} className="text-light-grey inline-flex items-center mt-2">
                          <span>Czytaj więcej</span>
                          <SvgIconArrow/>
                        </NewsLink>
                      </div>
                    </div>
                  </div>
                  )
                })
              }
            </div>
          </section>
        </div>

      </motion.div>
    </div>
  );
};
