import React from 'react';
import cn from 'classnames';
import styles from './PageContent.module.scss';
import {Loading} from '../Loading';
import {useHistory} from 'react-router';
import {createMarkup, navigate} from '../../services/Markup';


export const PageContent = ({ title, content, loading }) => {
  const history = useHistory();

  const clickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if(!targetLink) return;
    e.preventDefault();
    navigate(targetLink, history)
  }

  return (
    <div className='flex flex-1 bg-white pb-6 mx-4'>
      <div className="w-full pl-6 pr-6 sm:w-full md:w-full lg:w-2/3 xl:w-1/2 ml-auto mr-auto">
        <h1 className='text-38px font-black leading-48px mt-30px mb-6'>{title}</h1>

        <div className={cn('text-dark-grey', styles.content)}>

          <div
            onClick={clickHandler}
            dangerouslySetInnerHTML={createMarkup(content)}
          />

          {loading &&
          <Loading/>
          }
        </div>
      </div>
    </div>
  );
};
