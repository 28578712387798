import React from 'react'
import {Link} from "react-router-dom";

export const Breadcrumbs = ({breadcrumbs}) => {
    return (
        <span className='text-14px block mt-2 sm:truncate'>

            {
                breadcrumbs && <Link to='/'>STRONA GŁÓWNA</Link>
            }

            {
                breadcrumbs &&
                breadcrumbs.length > 0 &&
                breadcrumbs.map(item => (
                    <span key={item.ID}> > <Link to={`/page/${item.name}`} className='uppercase'> {item.title} </Link></span>
                ))
            }
                </span>
    )
}
