import React from 'react';
import {Link} from 'react-router-dom';
import {newsStyle} from '../../services/NewsStyle';
import {format, parseISO} from 'date-fns'
import {pl} from 'date-fns/locale'
import { NewsLink } from './NewsLink';


export const NewsCard = ({type, title, header, date, url, isExternal}) => {
  const {categoryText, categoryColor} = newsStyle(type);

  return (
      <div className="max-w shadow bg-white">
        <NewsLink to={url} isExternal={isExternal}>
          <img className="w-full h-141px mb-4 shadow-md object-cover object-top" src={header} alt={title}/>
        </NewsLink>
        <div className={`px-4 font-bold uppercase ${categoryColor} bg-white text-14px`}>
          {categoryText}
        </div>
        <div className="px-4 pt-2 bg-white">
          <div className="text-22px leading-extra-space min-h-129px">
            <NewsLink to={url} isExternal={isExternal}>{title}</NewsLink>
          </div>
          <div className='pt-4 border-b'/>
        </div>
        <div className='flex justify-between bg-white'>
          <div className='inline-block text-14px font-bold text-light-grey ml-4 mt-2 uppercase '>
            {format(parseISO(date), 'dd MMMM yyyy', { locale: pl })}
          </div>
          <div className="inline-block">
            <NewsLink to={url} isExternal={isExternal}>
              <img src='/assets/images/trojkat.png' alt='more'/>
            </NewsLink>
          </div>
        </div>
      </div>
  )
}
