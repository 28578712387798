import React from 'react';
import {SvgGrid} from './SvgGrid';

export const Loading = () => {
  return (
    <div className='flex w-full h-full'>
      <div className='m-auto mt-32'>
        <SvgGrid width="100" height="100"></SvgGrid>
      </div>

    </div>
  )
}
