import React, {useState} from 'react'
import SearchIcon from "./SearchIcon";

export const Search = ({onSearch, showHeader = true, initialValue}) => {
    const [value, setValue] = useState(initialValue)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') onSearch(value);
    }

    return (
        <>

            <div className='flex w-full'>
                <input placeholder='Szukaj informacji, dokumentów, aktualności'
                       value={value}
                       onKeyDown={handleKeyDown}
                       onChange={(e) => setValue(e.target.value)}
                       type="text"
                       className="rounded-l-lg min-w-0
                           p-4 leading-none flex-grow h-12 shadow text-gray-700 focus:outline-none focus:shadow-outline" />
                <button
                    onClick={() => onSearch(value)}
                    className="inline-flex
                        bg-green-600 px-3 py-3 text-white rounded-r-lg h-12 transition hover:bg-green-700 focus:bg-green-700">
                    <SearchIcon width={24}/>
                </button>
            </div>


        </>
    )
}
