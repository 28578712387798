import React from 'react';
import {BurgerMenu} from './BurgerMenu';
import {SocialMediaIcons} from "./SocialMediaIcons";
import {Breadcrumbs} from "./Breadcrumbs";


export const TopMenu = ({breadcrumbs}) => {
    return (
        <div className='mx-0 sm:mx-4 h-38px flex justify-between border-gray-300 border relative'>

            {/*<div className='w-38px h-38px mr-2 overflow-hidden'>*/}
            {/*    <BurgerMenu/>*/}
            {/*</div>*/}
            <div className='ml-2 w-2/5 invisible sm:visible '>
                <Breadcrumbs breadcrumbs={breadcrumbs}/>
            </div>

            <div className='pt-2'>
                <SocialMediaIcons/>
            </div>
        </div>
    );
};
