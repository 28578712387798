import React, {useCallback, useEffect, useState} from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react'
import {Layout} from '../components/Layout';
import {Header} from '../components/Header';
import {TopMenu} from '../components/TopMenu';
import {PageContent} from '../components/PageContent';
import {useLocation} from 'react-router-dom';
import {API} from '../services/API';
import {AdminMenu} from "../components/AdminMenu";
import {PasswordProctected} from "../components/PageContent/PasswordProctected";
import {Footer} from "../components/Footer";

export const Page = () => {
    const [data, setData] = useState({ID: 0, title: '', content: '', parents: [], secured: false});
    const [loading, setLoading] = useState(true)
    const {trackPageView} = useMatomo()
    let location = useLocation();

    const handleRequest = useCallback((password = '') => {
        setLoading(true);
        const fetchData = async () => {
            const slug = location.pathname.split('/').filter(e => e !== '').pop()
            const response = await API.get(`/wimiip/v1/post?slug=${slug}&password=${password}`)
            setData(response.data);
            setLoading(false);
        }
        fetchData();
    }, [location])

    const handleOnPassword = (password) => {
        handleRequest(password);
    }

    useEffect(() => {
        handleRequest()
    }, [handleRequest])

    useEffect(() => {
        trackPageView()
    }, [trackPageView])

    return (
        <Layout>
            <Header/>
            <TopMenu breadcrumbs={data.parents}/>
            {
                !data.secured && <PageContent {...data} loading={loading}/>
            }
            {
                data.secured && <PasswordProctected onPassword={handleOnPassword}/>
            }

            <AdminMenu postid={data.ID}/>
            <Footer/>
        </Layout>
    );
}
