import * as React from "react";

function SearchIcon(props) {
    return (
        <svg viewBox="0 0 512 512" fill="currentColor" {...props}>
            <path d="M464 428L339.92 303.9a160.48 160.48 0 0030.72-94.58C370.64 120.37 298.27 48 209.32 48S48 120.37 48 209.32s72.37 161.32 161.32 161.32a160.48 160.48 0 0094.58-30.72L428 464zM209.32 319.69a110.38 110.38 0 11110.37-110.37 110.5 110.5 0 01-110.37 110.37z" />
        </svg>
    );
}

export default SearchIcon;
