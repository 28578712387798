import React from 'react';
import EditIcon from "./EditIcon";

const openNewTabWithEditor = (postid) => {
    window.open(`https://www.metal.agh.edu.pl/wp/wp-admin/post.php?post=${postid}&action=edit`, '_blank').focus()
}

export const AdminMenu = ({postid}) => {
    const cookies = document.cookie;

    if(cookies.includes('wp-metal-editor')){
        return (
            <div className="flex justify-end bg-gray-200">
                <div className="p-2">
                    <button
                        role="link"
                        onClick={() => openNewTabWithEditor(postid)}
                        className="text-white px-4 w-auto h-10 rounded flex-shrink-0 bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-sm border-4 text-white">
                        <EditIcon enableBackground="new 0 0 20 20"  className="w-5 h-5 inline-block mr-2"/>
                        <span className=''>Edytuj stronę</span>
                    </button>

                </div>
            </div>
        )
    }

    return null
}
