import axios from 'axios';

const instance =  axios.create({
  baseURL: 'https://www.metal.agh.edu.pl/api'
});

// response parse
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if(error.response.status === 404){
      window.location.href = '/not-found'
    }
    return Promise.reject(error);
  });

export const API = instance
