export const createMarkup = encodedHtml => {
  return {
    __html: encodedHtml
  }
};

const openExternalUrl = (url, target = '_self') => {
  const win = window.open(url, target);
  win.focus();
}

export const navigate = (link, history) => {
  const href = link.href || link;

  if(href === '#') return;
  // const base = window.location.protocol+'//'+ window.location.hostname+(window.location.port ? ':'+ window.location.port: '');
  // const apiUrl = 'https://www.metal.agh.edu.pl/index.php/'

  const wpFileBase = 'https://www.metal.agh.edu.pl/wp/wp-content'


  if(href.startsWith('/')){
    return history.push(href)
  }

  if(link.target === '_blank'){
    return openExternalUrl(href, link.target)
  }

  if(href.startsWith(wpFileBase)){
    return openExternalUrl(href)
  }

  openExternalUrl(href)
}
