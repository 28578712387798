import React, {useEffect, useState} from 'react';
import {FooterItem} from './FooterItem';
import {API} from "../../services/API";
import {Copyrights} from "../Copyrights";

export const Footer = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await API.get(`/wp/v2/footer`)
      setData(response.data);
    }
    fetchData();
  }, [])

  return (
    <footer className='bg-black pt-8 bt-2'>
      <div className='flex flex-wrap'>
      {
        data.map((item, i) => (
            <FooterItem key={`footer-${i}`} content={item}/>
        ))
      }
      </div>
      <Copyrights/>
    </footer>
  )
}
