import React, {useEffect, useState} from 'react';

import {Slider} from "./Slider";
import {Search} from "./Search";
import {useHistory} from "react-router";
import {API} from "../../services/API";

const bgImages = [
    'https://www.metal.agh.edu.pl/wp/wp-content/uploads/2020/08/red_abstract_1.jpg',
    'https://www.metal.agh.edu.pl/wp/wp-content/uploads/2020/08/red_abstract_2.jpg',
    'https://www.metal.agh.edu.pl/wp/wp-content/uploads/2020/08/green_laptop.jpg',
    'https://www.metal.agh.edu.pl/wp/wp-content/uploads/2020/08/yellow_hand.jpg'
]

export const SearchBanner = () => {
    const history = useHistory();
    const [bgImage, setBgImage] = useState('')
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await API.get(`/wp/v2/banner`)
            if(response.data){
                setData(response.data.reverse())
            }
        }
        fetchData();
    }, [])


    useEffect(() => {
        const date = new Date();
        const day = date.getDay();
        const img = bgImages[day % bgImages.length];
        setBgImage(img)
    }, []);

    return (
        <div className='mx-0 md:mx-4 sm:min-h-386px relative flex flex-wrap bg-cover bg-gray-700'
             style={{backgroundImage: `url(${bgImage})`}}>
            <div className='flex flex-wrap w-1920px mx-auto'>
            <div className='
                    w-full md:w-1/2 lg:w-1/3
                    ml-auto
                    flex content-center flex-wrap
                    px-4 my-4 sm:mb-0 sm:px-24 md:px-4'>
                <Search onSearch={(text) => history.push(`/search?term=${text}`)}/>
            </div>
            <div className='
                    w-full md:w-1/2 lg:w-2/5
                    mr-auto
                    items-center
                    mb-4
                    my-0
                    px-0 sm:px-8 md:px-0'>
                <Slider data={data}/>
            </div>
            </div>
        </div>
    )
}
