import React from 'react';

export const Copyrights = () => {
  return (
    <div className='bg-black flex leading-26px text-14px pb-2 pt-2'>
      <div className='w-full text-center text-white opacity-25'>
        ©2023 All rights reserved Wydział Inżynierii Metali i Informatyki Przemysłowej
      </div>

    </div>
  )
}
