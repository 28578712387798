import React, {useState} from 'react'

export const PasswordProctected = ({onPassword}) => {
    const [password, setPassword] = useState('');

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') onPassword(password);
    }

    return (
        <div className='flex flex-1 bg-white pb-6 mx-4'>
            <div className="w-full pl-6 pr-6 py-4 sm:w-full md:w-full lg:w-2/3 xl:w-1/2 ml-auto mr-auto">
                <p>Zawartość tej strony przeznaczona jest tylko dla upoważnionych osób. Proszę podać hasło:</p>
                <input
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setPassword(e.target.value)}
                    className="my-4 appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    id="password" type="password" placeholder="******************">
                </input>
                <button
                    onClick={() => onPassword(password)}
                    className="flex-shrink-0 bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded"
                    type="button">
                    OK
                </button>
            </div>
        </div>
    )
}
