import React from 'react';
import {Link} from "react-router-dom";


const NavLink = ({url, children}) => {
  if (!url.startsWith('http')) {
    return <Link to={`/page${url}`}>{children}</Link>
  } else {
    return <a href={url}>{children}</a>
  }
}

export const NavCard = ({title, description, url}) => {

  return (
      <NavLink url={url}>
        <div className="overflow-hidden cursor-pointer">
          <div
              className="block h-79px px-6 py-4 bg-white border border-solid border-gray-400 hover:border-light-green shadow">
            <div className="overflow-hidden">
              <span className="block leading-relaxed font-bold text-16px font-bold uppercase truncate ">{title}</span>
              <span className="leading-relaxed text-gray-700 text-14px font-normal uppercase truncate ">
            {description}
          </span>
            </div>
          </div>
        </div>
      </NavLink>
  )
}
