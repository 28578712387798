export const newsStyle = (categoryId) => {
  let categoryText = '';
  let categoryColor = '';

  switch(categoryId){
    case 5:
      categoryText = 'OGŁOSZENIE';
      categoryColor = 'text-red';
      break;
    case 6:
      categoryText = 'WYDARZENIE';
      categoryColor = 'text-agh-green';
      break;
    default:
      categoryText = 'AKTUALNOŚCI';
      categoryColor = 'text-black';
  }

  return {categoryText, categoryColor}
}
