import React from 'react';
import './tailwind.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {NewsPage} from './pages/News';
import {Front} from './pages/Front';
import {Page} from './pages/Page';
import {unregister} from './registerServiceWorker';
import {Search} from "./pages/Search";
import {Error404} from "./pages/Error404";
import {MatomoProvider} from "@datapunt/matomo-tracker-react";
import {matomoInstance} from './services/Matomo'
import * as ReactDOMClient from 'react-dom/client';
const root = ReactDOMClient.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <MatomoProvider value={matomoInstance}>
    <Router basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path='/'>
          <Front/>
        </Route>
        <Route exact path='/index/:category'>
          <Front/>
        </Route>
        <Route exact path='/search'>
          <Search/>
        </Route>
        <Route exact path='/news/:id'>
          <NewsPage/>
        </Route>
        <Route path='/page/:p1?/:p2?/:p3?/:p4?/:slug'>
          <Page/>
        </Route>
        <Route path='/not-found'>
          <Error404/>
        </Route>
        <Route>
          <Error404/>
        </Route>
      </Switch>
    </Router>
    </MatomoProvider>
  </React.StrictMode>
);

unregister();
