import React from 'react'
import FacebookIcon from "./FacebookIcon";
import styles from './TopMenu.module.scss'
import InstagramIcon from './InstagramIcon';

export const SocialMediaIcons = () => {
    return (
        <div className='flex divide-x divide-gray-400'>
            <a className='px-2 text-gray-700 font-bold hover:text-shadow' href='https://www.usos.agh.edu.pl/'>
                <span className=''>USOS</span>
            </a>

            <a className='px-2 text-gray-700 font-bold hover:text-shadow' href='http://www.wrss-met.agh.edu.pl/'>
                <span>WRSS</span>
            </a>

            <a className='px-3 pt-1 text-gray-700 font-bold ' href='https://pl-pl.facebook.com/wimiip'>
                <span>
                    <FacebookIcon width={16} className={styles.icon} alt="Our facebook"/>
                </span>
            </a>

            <a className='px-3 pt-1 text-gray-700 font-bold ' href='https://www.instagram.com/wimiip.agh/'>
              <span>
                    <InstagramIcon width={16} className={styles.icon} alt="Our Instagram"/>
              </span>
            </a>
        </div>
    )
}
