import React, {useCallback, useEffect, useState} from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import {Search} from "../SearchBanner/Search";
import {useHistory, useLocation} from "react-router-dom";
import queryString from "query-string";
import {API} from "../../services/API";
import {Results} from "./Results";

export const SearchResults = () => {
    const location = useLocation();
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState(queryString.parse(location.search).term)
    const [results, setResults] = useState([])
    const { trackSiteSearch } = useMatomo()

    const handleOnSearchCb = useCallback((text) => {
        const handleOnSearch = (text) => {
            history.push(`/search?term=${text}`)

            const fetchData = async () => {
                const response = await API.get(`/wimiip/v1/search?s=${text}`)
                setResults(response.data)
                trackSiteSearch({keyword: text, category: false, count: response.data.length})
            }
            fetchData();
        }
        handleOnSearch(text);
    }, [history, trackSiteSearch])

    useEffect(() => {
        setSearchTerm(queryString.parse(location.search).term)
    }, [location])

    useEffect(() => {
        handleOnSearchCb(searchTerm)
    }, [searchTerm, handleOnSearchCb])


    return (
        <div className='block min-h-82px'>
            <div className='py-4'>
                <Search showHeader={false} initialValue={searchTerm} onSearch={handleOnSearchCb}/>
            </div>
            <div>
                <Results results={results}/>
            </div>

        </div>
    )

}
