import * as React from 'react';

export const SvgIconCircle = (props) => {
  const {selected} = props;

  if(selected){
    return (
      <svg viewBox="0 0 400 400" {...props}>
        <path
          fill="#FFF"
          d="M197 356.5c-86.294 0-156.5-70.206-156.5-156.5S110.706 43.5 197 43.5 353.5 113.706 353.5 200 283.294 356.5 197 356.5zm0-273c-64.238 0-116.5 52.261-116.5 116.5S132.762 316.5 197 316.5 313.5 264.238 313.5 200 261.238 83.5 197 83.5z"
        />
      </svg>
    );
  }

  return (
    <svg viewBox="0 0 400 400" {...props}>
      <path
        fill="#FFF"
        d="M197 344c-79.402 0-144-64.598-144-144S117.598 56 197 56s144 64.598 144 144-64.598 144-144 144zm0-273c-71.13 0-129 57.87-129 129 0 71.131 57.869 129 129 129s129-57.869 129-129c0-71.13-57.869-129-129-129z"
      />
    </svg>
  )

}
